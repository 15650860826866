import React, { useEffect, useReducer } from 'react'

import {
  DepartmentsListWithPlans,
  CandidateRejectForm,
  RecallForm,
  SchedulerCustom
} from '../../index'
import { appConfig } from '../../../../../constants/appConfig'
import { UtmModal } from '../UtmModal'
import { Form } from 'antd'
import { useReferences } from '../../../../../hooks/useReferences'
import { simpleReducer } from '../../../../../helpers'
import CandidateSelectDepartmentForAction from '../CandidateSelectDepartmentForAction'
// import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'

const {
  // rejectedByCandidate,
  // rejectedByDepartment,
  // rejectedByRecruiter,
  // rejectedByHRBP,
  // rejectedByOD,
  // hrInterview,
  // hrBPReview,
  // odInterview,
  // rescheduleODInterview,
  // hrBPInterview,
  // rescheduleHRBPInterview,
  // scheduleInterview,
  // rescheduleInterview,
  // rescheduleHRInterview,
  changeDepartment
  // preInterview,
  // createdByHiringManager,
  // reviewRejectedByHRBP,
  // sendForEmployment
} = appConfig.workflows.candidate.actions

// const { later } = appConfig.workflows.call.actions

/**
 * Need replace all cases and returned Components
 */
function ActionForm(props) {
  // const { isAmpCandidate } = useContext(CandidatePanelContext)
  const [form] = Form.useForm()
  const initUtms = {
    requestType: null,
    utm_medium: null
  }
  const [utms, setUtms] = useReducer(simpleReducer, initUtms)

  useEffect(() => {
    /**
     * Очищаем форму от предыдущего профиля
     * так как в новом профиле может не быть некоторых полей например skype
     * мы предотвращаем сохранение этих полей от предыдущего профиля
     */
    form.resetFields()
    setUtms({
      requestType: props.candidate.requestType,
      utm_medium: props.candidate.utm_medium
    })
  }, [form, props.candidate])

  useReferences({
    requestTypes: true,
    utms: true,
    utmsQuery: utms.requestType
  })
  const handleSetRequestType = value => {
    setUtms({
      requestType: value,
      utm_medium: null
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null
    })
  }
  const handleSetUtmMedium = value => {
    setUtms({
      ...utms,
      utm_medium: value
    })
    form.setFieldsValue({
      utm_source: null
    })
  }
  // Отказы
  if (
    Object.values(appConfig.workflows.steps.rejectionTypes)?.includes(props.action?.currentStep)
  ) {
    return (
      <CandidateRejectForm
        {...props}
        buttonsProps={{ ok: { className: 'green' }, cancel: { className: 'red' } }}
      />
    )
  }
  // Интервью - рестораны и расписание по времени
  if (props.action?.currentStep === appConfig.workflows.steps.interviews.departmentsListWithPlans) {
    return (
      <DepartmentsListWithPlans
        {...props}
        title={undefined}
        tableHeight={350}
        needFilter={changeDepartment === props.action?.name}
      />
    )
  }
  if (
    props.action?.currentStep === appConfig.workflows.steps.interviews.schedulerCustom ||
    props.action?.currentStep === appConfig.workflows.steps.interviews.adminScheduler
  ) {
    return <SchedulerCustom {...props} department={props.action?.department} />
  }
  // Звонки
  if (props.action?.currentStep === appConfig.workflows.steps.calls.later) {
    return <RecallForm {...props} />
  }
  // Модалки
  if (props.action?.currentStep === appConfig.workflows.steps.modals.utm) {
    return (
      <UtmModal
        open={true}
        onOk={() => {
          const values = form.getFieldsValue()
          props.onAction(props.action, values)
        }}
        onCancel={() => {
          props.onAction(null, null)
        }}
        form={form}
        requestType={utms?.requestType}
        utmMedium={utms?.utm_medium}
        onRequestTypeChange={handleSetRequestType}
        onUtmMediumChange={handleSetUtmMedium}
      />
    )
  }
  // АУП
  if (
    props.action?.currentStep === appConfig.workflows.steps.interviews.selectDepartmentForAction
  ) {
    return (
      <CandidateSelectDepartmentForAction
        {...props}
        buttonsProps={{ ok: { className: 'green' }, cancel: { className: 'red' } }}
      />
    )
  }
  return null
}
export default ActionForm
