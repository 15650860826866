import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout, Button, Avatar, Col, Row, message } from 'antd'
import { UserContext } from '../../../../contexts/userContext'

import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons'
import {
  EXTERNAL_BUTTON,
  FORGOT_PASSWORD,
  NEW_CANDIDATE_PAGE,
  PASSWORD_RECOVERY
} from '../../../../constants/routes'
import { useMutateLogout } from '../../../../api/auth'
import CommonSearch from './CommonSearch'
import { useQueryClient } from 'react-query'
import { acronym } from '../../../../helpers'

const { Header: LayoutHeader } = Layout

const Header = ({ collapsed, toggle }) => {
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const { user, logout, isAdmin, isManager, isHiringManager, isRecruiter } = useContext(UserContext)
  const { mutate, isError, isLoading } = useMutateLogout(queryClient)

  useEffect(() => {
    if (isError) {
      message.error('Ошибка выхода')
    }
  }, [isError])

  const handleCreateCandidate = useCallback(() => {
    navigate(NEW_CANDIDATE_PAGE)
  }, [navigate])

  const handleClick = useCallback(() => {
    mutate()
    logout()
  }, [mutate, logout])

  // Don't show on Auth page
  const { pathname } = useLocation()
  if (
    pathname === '/' ||
    pathname === PASSWORD_RECOVERY ||
    pathname === EXTERNAL_BUTTON ||
    pathname === FORGOT_PASSWORD ||
    !Object.keys(user).length
  )
    return null

  return (
    <LayoutHeader className="header">
      <Row align="middle">
        <Col span={1} className="header-icons">
          {collapsed ? (
            <MenuUnfoldOutlined className="trigger" onClick={toggle} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={toggle} />
          )}
        </Col>

        <Col span={23}>
          <Row align="middle" justify="space-between" className="ml-3">
            <Col className="search-wrapper">
              <Row align="middle">
                {(isHiringManager || isRecruiter) && (
                  <Col className="create-candidate">
                    <Button type="default" size="large" onClick={handleCreateCandidate}>
                      Добавить кандидата
                    </Button>
                  </Col>
                )}

                <Col className="search">
                  {(isAdmin || isManager || isHiringManager || isRecruiter) && (
                    <CommonSearch searchByButton />
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="profile">
                <Avatar
                  size={45}
                  icon={user ? acronym(user.name) : <UserOutlined />}
                  className="header-avatar"
                />
                <div>
                  <div>{user.name}</div>
                  <div>{user.department?.name}</div>
                </div>

                <Button
                  type="primary"
                  danger
                  className="btn-exit"
                  size="large"
                  onClick={handleClick}
                  loading={isLoading}
                >
                  Выход
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutHeader>
  )
}

export default Header

Header.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}
