import React, { useCallback, useRef } from 'react'
import { Form, Input } from 'antd'

import { PatternFormat } from 'react-number-format'
import { colSettings } from '../../../constants'
import { PHONE_VALIDATION_MESSAGE, validatePhone } from '../../../helpers/phone'
import { antdInputHandleChange } from '../../../helpers'

const PhoneItem = ({
  className = '',
  label = 'Телефон',
  name = 'phone',
  labelCol = colSettings.full,
  wrapperCol = colSettings.full,
  labelAlign = 'right',
  required = false,
  allowClear = false,
  disabled = false,
  autoComplete = 'off',
  size = 'middle',
  onChange
}) => {
  const inputRef = useRef(null)
  const handleChange = useCallback(
    event => {
      //https://github.com/s-yadav/react-number-format/issues/818#issuecomment-2067805933
      antdInputHandleChange(event, inputRef)
      return onChange?.(event)
    },
    [onChange]
  )

  return (
    <Form.Item
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      name={name}
      rules={[
        {
          required,
          message: PHONE_VALIDATION_MESSAGE,
          validator: required ? validatePhone : undefined
        }
      ]}
    >
      <PatternFormat
        size={size}
        format="+# (###) ### ## ##"
        allowEmptyFormatting
        mask="_"
        className={`ant-input ${className}`}
        autoComplete={autoComplete}
        onChange={handleChange}
        disabled={disabled}
        customInput={Input}
        getInputRef={inputRef}
        allowClear={allowClear}
      />
    </Form.Item>
  )
}

export default PhoneItem
