import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import { Button, Col, Form, Row, Spin, Typography } from 'antd'
import dayjs from 'dayjs'

import { FioItem, BirthdayItem, PhoneItem, DepartmentNumberItem } from '../FormItems/OrisFormItems'
import { appConfig } from '../../../../constants/appConfig'
import { apiErrorSaveOris, nbsp } from '../../../../constants'
import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { UserContext } from '../../../../contexts/userContext'
import { useMutateFlowAction } from '../../../../api/flow/candidate'
import { simpleReducer } from '../../../../helpers'
import { formatPhoneNumberForApi, formatPhoneNumber } from '../../../../helpers/phone'
import './OrisForm.less'
import { useMutateDadataFio } from '../../../../api/dadata'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { useQueryClient } from 'react-query'
const { Text } = Typography

const OrisForm = () => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { candidate, showPanelTab } = useContext(CandidatePanelContext)
  const { user } = useContext(UserContext)
  const { getDepartmentSingularName } = useConfigContextData()

  const initialState = {
    apiError: false,
    wasFormChanged: false,
    department: candidate?.application?.department || user.department,
    fio: ''
  }

  const [state, dispatch] = useReducer(simpleReducer, initialState)

  // Метод апи для работы с flow actions
  const {
    mutate: postAction,
    // data: dataPostAction,
    isSuccess: isSuccessPostAction,
    isError: isErrorPostAction,
    isLoading: isLoadingPostAction
  } = useMutateFlowAction(queryClient)

  const { isLoading: isLoadingDadataFio, mutate: mutateDadataFio } = useMutateDadataFio()

  const fillForm = useCallback(() => {
    const values = {
      name: candidate.name,
      birthday: candidate.birthday,
      phone: candidate.phone
    }
    form.setFieldsValue({
      ...values,
      birthday: values.birthday && dayjs(values.birthday).isValid() ? dayjs(values.birthday) : '',
      phone: formatPhoneNumberForApi(values.phone ? values.phone : candidate.phone),
      departmentExternalId: state.department?.externalId
    })
    dispatch({ fio: values.name })
  }, [form, candidate, state.department])

  useEffect(() => {
    if (isSuccessPostAction) {
      dispatch({ apiError: false, wasFormChanged: false })
      showPanelTab?.('0')
      // dataPostAction?.data?.candidate?.checkup?.url &&
      //   window.open(dataPostAction?.data?.candidate?.checkup?.url, '_blank')
    } else if (isErrorPostAction) {
      dispatch({ apiError: true })
    }
  }, [isSuccessPostAction, isErrorPostAction])

  useEffect(() => {
    fillForm()
  }, [fillForm])

  const handleValuesChange = () => {
    dispatch({ wasFormChanged: true })
  }

  const handleFinish = values => {
    const data = {
      ...values,
      phone: formatPhoneNumber(values.phone, true),
      birthday:
        values?.birthday && dayjs(values?.birthday).isValid()
          ? dayjs(values?.birthday).format(appConfig.formats.shortDateApi)
          : undefined,
      candidateId: candidate._id,
      applicationId: candidate?.application?._id
    }
    mutateDadataFio([values.name], {
      onSuccess: e => {
        let name = `${e.data?.[0]?.surname} ${e.data?.[0]?.name}`
        if (e.data?.[0]?.patronymic) {
          name += ` ${e.data?.[0]?.patronymic}`
        }
        // if (!e.data?.[0]?.surname || !e.data?.[0]?.name) {
        //   let fioError = ''
        //   if (!e.data?.[0]?.surname) fioError += 'Фамилия '
        //   if (!e.data?.[0]?.name) fioError += 'Имя '
        //   if (!e.data?.[0]?.patronymic) fioError += 'Отчество'
        //   dispatch({ fioError })
        //   form.setFields([
        //     {
        //       name: 'name',
        //       errors: [`Отсутствует ${fioError}`]
        //     }
        //   ])
        // } else {
        data.dadataName = name.trim()
        postAction({
          path:
            appConfig.workflows.candidate.prefixUrl +
            appConfig.workflows.candidate.actions.sendToMedicalTest,
          data
        })
        // }
      },
      onError: () => {
        dispatch({ fioError: true })
      }
    })
  }

  const readOnly = !!(
    candidate?.checkup?.number || candidate.state === appConfig.statuses.values.waitingForCheckup
  )
  return (
    <Spin spinning={isLoadingDadataFio || isLoadingPostAction} size="large">
      <Form
        form={form}
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
        className="orisForm"
      >
        <FioItem readOnly={readOnly} required={false} />
        <BirthdayItem readOnly={readOnly} />
        <PhoneItem readOnly={readOnly} />
        <DepartmentNumberItem name={state.department?.name} value={state.department?.externalId} />
        <Row className="mb-3">
          <Col span={5}>
            <Text>Дата прохождения медосмотра:</Text>
          </Col>
          <Col span={6}>
            <Text>
              {candidate?.checkup?.date && dayjs(candidate?.checkup?.date).isValid()
                ? dayjs(candidate?.checkup?.date).format(appConfig.formats.shortDateApi)
                : '-'}
            </Text>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={5}>
            <Text>Дата готовности ЛМК:</Text>
          </Col>
          <Col span={6}>
            <Text>
              {candidate?.checkup?.docIssueDate && dayjs(candidate?.checkup?.docIssueDate).isValid()
                ? dayjs(candidate?.checkup?.docIssueDate).format(appConfig.formats.shortDateApi)
                : '-'}
            </Text>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={5}>
            <Text>Номер заявки из DAX:</Text>
          </Col>
          <Col span={6}>
            <Text>{candidate?.checkup?.number || '-'}</Text>
          </Col>
        </Row>
        {candidate.state.name === appConfig.statuses.values.cameForAnInterview && (
          <div className="btn-wrap" style={{ marginTop: 20 }}>
            <Button htmlType="submit" style={{ marginRight: 20 }} type="primary" className="green">
              Создать направление в {nbsp}
              <b>DAX</b>
            </Button>
            {state.apiError && apiErrorSaveOris(getDepartmentSingularName())}
          </div>
        )}
        {candidate?.checkup?.url && !state.wasFormChanged && (
          <a target="_blank" rel="noreferrer" href={candidate.checkup.url}>
            Для создания направления пройдите по ссылке.
          </a>
        )}
      </Form>
    </Spin>
  )
}

export default OrisForm
