import React, { useCallback, useEffect, useMemo } from 'react'
import { Checkbox, Form, Input, Select } from 'antd'

import { colSettings } from '../../../../../constants'
import { useGetUserFields } from '../../../../../api/users'

// const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

function UserFieldsFormItem({ form }) {
  const { data: userFields } = useGetUserFields({ retry: 0 })
  const fields = useMemo(() => userFields?.data, [userFields?.data])

  useEffect(() => {
    fields?.forEach(field => {
      let userFields = form.getFieldValue('userFields')
      if (!userFields?.[field._id] && field.defaultValue !== undefined) {
        form.setFieldsValue({ userFields: { ...userFields, [field._id]: field.defaultValue } })
      }
    })
  }, [fields, form])

  const renderField = useCallback(field => {
    switch (field.controlType) {
      case 'select':
        return (
          <Select
            // size="small"
            placeholder={field.placeholder}
            notFoundContent="Нет вариантов"
            defaultValue={field.defaultValue}
            mode={field.multi ? 'multiple' : ''}
            // onChange={onChange}
            disabled={!field.enable}
            // getPopupContainer={trigger => trigger.parentNode}
          >
            {field.options.map(c => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        )
      case 'textarea':
        return (
          <TextArea
            // maxLength={field.maxLength}
            placeholder={field.placeholder}
            disabled={!field.enable}
            defaultValue={field.defaultValue}
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            defaultChecked={field.defaultValue}
            placeholder={field.placeholder}
            disabled={!field.enable}
          />
        )
      case 'text':
        return (
          <Input
            defaultValue={field.defaultValue}
            // maxLength={field.maxLength}
            placeholder={field.placeholder}
            disabled={!field.enable}
            type={field.dataType}
          />
        )
      default:
        return null
    }
  }, [])
  return (
    <div>
      {/*<Text type="secondary">Пользовательские поля</Text>*/}
      {fields
        ?.filter(item => item.enable)
        ?.map(field =>
          field ? (
            <Form.Item
              key={field._id}
              label={field.displayName}
              labelCol={colSettings.full}
              wrapperCol={colSettings.full}
              name={['userFields', field._id]}
              rules={[
                {
                  required: field.required,
                  message: `Укажите ${field.displayName?.toLowerCase()}`,
                  ...(field.controlType === 'checkbox'
                    ? { transform: value => value || undefined, type: 'boolean' }
                    : {})
                },
                ...(field.maxLength
                  ? [
                      {
                        max: field.maxLength,
                        message: `Используйте не больше ${field.maxLength} символов`
                      }
                    ]
                  : [])
              ]}
              valuePropName={field.controlType === 'checkbox' ? 'checked' : 'value'}
            >
              {renderField(field)}
            </Form.Item>
          ) : null
        )}
    </div>
  )
}

export default UserFieldsFormItem
