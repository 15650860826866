import { useMutation } from 'react-query'
import { api } from '../api'
import { APPOINTMENTS_KEY } from '../appointment'
import { CANDIDATE_KEY, CANDIDATES_KEY, CANDIDATES_SEARCH_KEY } from '../candidate'
import { AMP_KEY } from '../amp'

const putCandidateComment = ({ id, data }) => {
  return api.put({ path: `candidates/${id}/comments`, data })
}

const deleteCandidateComment = ({ id, commentId }) => {
  return api.del({ path: `candidates/${id}/comments/${commentId}` })
}

const patchCandidateComment = ({ id, commentId, data }) => {
  return api.patch({ path: `candidates/${id}/comments/${commentId}`, data })
}

const onSuccessMutateCandidateComment = queryClient => (data, variables) => {
  data?.data?._id && queryClient.setQueryData([CANDIDATE_KEY, variables.id], data)
  queryClient.refetchQueries(CANDIDATE_KEY, { active: true })
  queryClient.refetchQueries(CANDIDATES_KEY)
  queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
  queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
  queryClient.invalidateQueries(AMP_KEY)
  queryClient.refetchQueries(AMP_KEY, { active: true })
}

export function useMutateCreateCandidateComment(queryClient) {
  return useMutation(putCandidateComment, {
    onSuccess: onSuccessMutateCandidateComment(queryClient)
  })
}

export function useMutateDeleteCandidateComment(queryClient) {
  return useMutation(deleteCandidateComment, {
    onSuccess: onSuccessMutateCandidateComment(queryClient)
  })
}

export function useMutateUpdateCandidateComment(queryClient) {
  return useMutation(patchCandidateComment, {
    onSuccess: onSuccessMutateCandidateComment(queryClient)
  })
}
