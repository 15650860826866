import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Form, Spin, message, Select, Divider, Typography } from 'antd'
import { useRecoilValue } from 'recoil'

import { appConfig } from '../../../../constants/appConfig'
import {
  RoleItem,
  NameItem,
  EmailItem,
  PhoneItem,
  ActiveFlagItem,
  ModulesItem
} from '../../Components/FormItems/UserItems'
import './UserModalForm.less'
import { useMutateCreateUser, useMutateUser, useUsers } from '../../../../api/users'
import { formatPhoneNumberForApi } from '../../../../helpers/phone'
import { colSettings, modules as appModules } from '../../../../constants'
import { UserContext } from '../../../../contexts/userContext'
import { getDisabledRolesForUser } from '../../../../helpers/action'
import AgencyItem from '../FormItems/UserItems/AgencyItem'
import { useAgenciesQuery } from '../../../../api/agency'
import { ConfigContext } from '../../../../contexts/configContext'
import { useGetOrgStructure, useMutateAddOrgStructure } from '../../../../api/orgStructure'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'
import { useGetLinkedUsersJobSite } from '../../../../api/integrations'
import { debounce } from '../../../../helpers'
import { useQueryClient } from 'react-query'

const modes = appConfig.userFormModes
const { Option } = Select
const { Text } = Typography

const modalTitle = {
  [modes.create]: 'Новый пользователь',
  [modes.edit]: 'Редактирование пользователя'
}
const defaultModules = [appConfig.modules.crewman]
const validRoles = [
  appConfig.roles.manager,
  appConfig.roles.recruiter,
  appConfig.roles.prioritySelector,
  appConfig.roles.admin
]
const bkValidRoles = [appConfig.roles.recruiter, appConfig.roles.admin]
const bkRmpValidRoles = [appConfig.roles.manager, appConfig.roles.partner]
const validRolesWithHM = [appConfig.roles.hiringManager, ...validRoles]
const emailErrorMessage = 'Данная почта используется другим пользователем системы.'

function UserModalForm({
  afterSave,
  onCreateMode,
  user,
  withoutButton = false,
  isOpen = false,
  roles,
  roleDisable = false,
  positionDisable = false,
  position,
  onClose,
  userPositions,
  operationRegions,
  onChangeOperationRegion,
  modules = defaultModules
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { isAdmin, user: currentUser } = useContext(UserContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isRecruiter, setIsRecruiter] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const [isPrioritySelector, setIsPrioritySelector] = useState(false)
  const [disabledRoles, setDisabledRoles] = useState([])
  const mode = user?._id ? modes.edit : modes.create
  const [email, setEmail] = useState(null)

  const isEditYourself = useMemo(
    () => isAdmin && currentUser._id === user?._id,
    [isAdmin, currentUser, user]
  )

  useEffect(() => {
    if (user) setEmail(user.email)
  }, [user])

  const {
    mutate: createUser,
    data: createData,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
    isLoading: isLoadingCreate,
    error: errorCreate
  } = useMutateCreateUser(queryClient)

  const {
    features: { data: features, isLoaded: isLoadedFeatures }
  } = useContext(ConfigContext)
  const { data: agenciesData } = useAgenciesQuery(
    {},
    {
      enabled: isLoadedFeatures && features?.includes(appConfig.features.agencies)
    }
  )

  const agencies = useMemo(() => {
    return agenciesData?.data?.filter(({ locked }) => !locked) || []
  }, [agenciesData?.data])

  useEffect(() => {
    if (isSuccessCreate) {
      afterSave(createData?.data)
      setIsModalVisible(false)
    } else if (isErrorCreate) {
      if (errorCreate.response?.status === 409) {
        message.error('Ошибка: пользователь с таким email уже существует')
      } else {
        message.error('Ошибка создания пользователя')
      }
    }
  }, [createData, isSuccessCreate, isErrorCreate, afterSave, errorCreate])

  const {
    mutate: saveUser,
    isSuccess: isSuccessSave,
    isError: isErrorSave,
    isLoading: isLoadingSave
  } = useMutateUser(queryClient)

  useEffect(() => {
    if (isSuccessSave) {
      afterSave()
      setIsModalVisible(false)
    } else if (isErrorSave) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isSuccessSave, isErrorSave, afterSave])

  const { data: dataOrgStructure, isError: isErrorGetOrgStructure } = useGetOrgStructure()
  useEffect(() => {
    if (isErrorGetOrgStructure) {
      message.error('Ошибка получения данных')
    }
  }, [isErrorGetOrgStructure])

  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure
  } = useMutateAddOrgStructure(queryClient)

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  const { data: usersData, isError: isErrorUsers } = useUsers(
    {
      filters: {
        text: email
      }
    },
    {
      enabled: !!email
    }
  )
  const checkEmail = useCallback(
    email => {
      if (mode === modes.create) {
        return usersData?.data && email ? usersData?.data?.find(u => u.email === email) : false
      } else {
        return usersData?.data && email && user?._id
          ? usersData?.data?.find(u => u.email === email && user?._id !== u._id)
          : false
      }
    },
    [usersData?.data, user?._id, mode]
  )

  useEffect(() => {
    let existUser = checkEmail(email)

    if (existUser) {
      form.setFields([
        {
          name: 'email',
          errors: [emailErrorMessage]
        }
      ])
    }
  }, [email, form, checkEmail])

  useEffect(() => {
    if (isErrorUsers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [isErrorUsers])
  const debounceCheckEmail = useMemo(() => debounce(value => setEmail(value), 800), [])

  const { data: dataGetUsersJobSite, isError: isErrorGetUsersJobSite } = useGetLinkedUsersJobSite(
    appConfig.integrationSites.hrm,
    {
      enabled: (isOpen || isModalVisible) && features?.includes(appConfig.features.hrm)
    }
  )

  useEffect(() => {
    if (isErrorGetUsersJobSite) {
      message.error('Ошибка получения списка ботов')
    }
  }, [isErrorGetUsersJobSite, dataGetUsersJobSite])
  const jobSiteDataBots = useMemo(() => dataGetUsersJobSite?.data, [dataGetUsersJobSite])
  const errorMsgUserWithActiveBot = useMemo(() => {
    if (!user) return null
    const bots = jobSiteDataBots?.filter(bot => bot.internal?.user === user?._id)
    return bots?.length
      ? `Данный рекрутер прикреплен ${
          bots.length > 1
            ? 'к активным ботам: ' + bots.map(b => b.external?.name).join(', ') + ' -'
            : 'к активному боту ' + bots[0].external?.name
        } и не может быть отключен.`
      : null
  }, [jobSiteDataBots, user])

  const prepareFormByRolesAndAgency = useCallback(
    (roles, agencyId) => {
      setIsRecruiter(roles?.includes(appConfig.roles.recruiter))
      setIsManager(roles?.includes(appConfig.roles.manager))
      setIsPrioritySelector(roles?.includes(appConfig.roles.prioritySelector))
      let data = getDisabledRolesForUser(roles, agencyId)
      if (errorMsgUserWithActiveBot && !data.includes(appConfig.roles.recruiter)) {
        data.push(appConfig.roles.recruiter)
      }
      setDisabledRoles(data)
    },
    [errorMsgUserWithActiveBot]
  )

  useEffect(() => {
    if (user?._id) {
      form.setFieldsValue({
        ...user,
        active: !user.locked,
        phone: user.phone ? formatPhoneNumberForApi(user.phone) : '',
        roles: user.roles,
        position: user.position?._id,
        /**
         * агентство у юзера может быть заблокировано. значит в общем списке агентств в селекте его не будет
         * в этом случае в селекте покажется идешка агенства. чтобы этого избежать, только в этом случае
         * передаем на форму в селект имя этого заблокированного агенства
         */
        agency:
          agenciesData?.data?.find(agency => agency._id === user.agency?._id && agency.locked)
            ?.name || user.agency?._id
      })
      prepareFormByRolesAndAgency(user.roles, user.agency?._id)
      setIsModalVisible(true)
    }
  }, [prepareFormByRolesAndAgency, user, form, agenciesData])

  useEffect(() => {
    if (roles?.length) {
      form.setFieldsValue({ roles })
      prepareFormByRolesAndAgency(roles)
    }
  }, [prepareFormByRolesAndAgency, roles, form])

  useEffect(() => {
    if (isOpen && position) {
      form.setFieldsValue({ position })
    }
  }, [isOpen, position, form])

  const showModal = () => {
    onCreateMode && onCreateMode()
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    onClose && onClose()
    setEmail('')
    setIsModalVisible(false)
  }

  const handleAfterClose = () => {
    setIsRecruiter(false)
    setIsManager(false)
    setIsPrioritySelector(false)
    setDisabledRoles([])
    form.resetFields()
  }

  const getAgencyIdForm = useCallback(
    agencyId => {
      if (!isRecruiter || !agencyId) {
        return null
      }
      return agencies?.find(agency => agency._id === agencyId)?._id || user.agency?._id
    },
    [isRecruiter, agencies, user]
  )

  const setUnitUsers = useCallback((data, user) => {
    data.users = [...(data.users?.filter(id => id !== user._id) || [])]
    data?.children?.forEach(unit => setUnitUsers(unit, user))
  }, [])

  const handleFinish = async values => {
    if (mode === modes.edit) {
      await saveUser({
        id: user._id,
        data: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          position: values.position,
          locked: !values.active,
          ...(values.roles.length > 0 && { roles: values.roles }),
          agency: getAgencyIdForm(values.agency),
          modules: values.modules || modules
        }
      })
      /**
       * Если у пользователя была роль “Менеджер”, то после удаления у него роли “Менеджер” необходимо:
       * удалить из оргструктуры id этого пользователя.
       */
      if (
        user.roles?.includes(appConfig.roles.manager) &&
        !values.roles?.includes(appConfig.roles.manager)
      ) {
        // обновляем в стрруктуре юнит - удаляем пользователя
        const units = { children: [...(dataOrgStructure?.data?.units || [])] }
        setUnitUsers(units, user)
        await mutateOrgStructure({
          layers: dataOrgStructure?.data?.layers,
          units: units.children
        })
      }
    } else {
      await createUser({
        modules,
        ...values,
        login: values.email,
        locked: !values.active
      })
    }
  }

  const handleFormChange = (changedValues, allValues) => {
    prepareFormByRolesAndAgency(allValues.roles, allValues.agency)
  }

  const isShownAgencyItem = useMemo(() => {
    if (user?.agency?._id && !features.includes(appConfig.features.agencies)) {
      return true
    } else if (
      features.includes(appConfig.features.agencies) &&
      isRecruiter &&
      !isPrioritySelector
    ) {
      return true
    }
    return false
  }, [isRecruiter, isPrioritySelector, features, user?.agency?._id])

  const isDisabled = useMemo(
    () => roleDisable || (mode === modes.edit && moduleName === appModules.bk),
    [moduleName, mode, roleDisable]
  )
  const usingRoles = useMemo(() => {
    // для заблокированного показываем все роли
    if (isDisabled) return Object.values(appConfig.roles)
    if (moduleName === appModules.bk) {
      if (operationRegions) return bkRmpValidRoles
      return bkValidRoles
    }
    return mode === modes.edit && user?.roles?.includes(appConfig.roles.hiringManager)
      ? validRolesWithHM
      : validRoles
  }, [user?.roles, mode, moduleName, operationRegions, isDisabled])

  return (
    <div>
      {!withoutButton && (
        <Button type="primary" onClick={showModal}>
          + Добавить пользователя
        </Button>
      )}
      <Modal
        width={530}
        title={<div className="no-gutters block-title">{modalTitle[mode]}</div>}
        open={isModalVisible || isOpen}
        cancelText="Отмена"
        okText="Сохранить"
        cancelButtonProps={{ type: 'link' }}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoadingCreate || isLoadingSave}
        className="userModalForm"
        afterClose={handleAfterClose}
      >
        <Spin spinning={isLoadingCreate || isLoadingSave}>
          <Divider rootClassName="small" />
          <Form form={form} onFinish={handleFinish} onValuesChange={handleFormChange}>
            <RoleItem
              roles={usingRoles}
              disabled={isDisabled}
              labelCol={colSettings.full}
              size="large"
              adminOptionDisabled={isEditYourself}
              disabledRoles={disabledRoles}
            />
            {isRecruiter && moduleName === appModules.bk && <ModulesItem />}
            {isManager && (
              <Form.Item
                label="Позиция менеджера"
                labelCol={colSettings.full}
                wrapperCol={colSettings.full}
                labelAlign="left"
                required={isManager}
                name="position"
                rules={[
                  {
                    required: isManager,
                    message: 'Выберите позицию'
                  }
                ]}
              >
                <Select
                  size="large"
                  placeholder="Выберите позицию"
                  disabled={
                    (mode === modes.edit && moduleName === appModules.bk) || positionDisable
                  }
                >
                  {userPositions?.map(userPosition => (
                    <Option key={userPosition._id} value={userPosition._id}>
                      {userPosition.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {operationRegions && (
              <Form.Item
                label="Операционный регион"
                labelCol={colSettings.full}
                wrapperCol={colSettings.full}
                labelAlign="left"
                name="operationRegion"
                rules={[
                  {
                    required: true,
                    message: 'Выберите операционный регион'
                  }
                ]}
              >
                <Select
                  size="large"
                  placeholder="Выберите операционный регион"
                  onSelect={item => onChangeOperationRegion?.(item)}
                >
                  {operationRegions?.map(or => (
                    <Option key={or.name} value={or.name}>
                      {or.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <NameItem labelCol={colSettings.full} size="large" />
            <EmailItem
              size="large"
              labelAlign="left"
              disabled={mode === modes.edit && moduleName === appModules.bk}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    debounceCheckEmail(value)

                    if (!value) return Promise.reject()
                    rule.message = emailErrorMessage

                    return checkEmail(value) ? Promise.reject() : Promise.resolve()
                  },
                  message: 'Укажите почту'
                }
              ]}
            />
            {isShownAgencyItem && (
              <AgencyItem agencies={agencies} labelCol={colSettings.full} size="large" />
            )}
            {isRecruiter && <PhoneItem className="ant-input-lg" labelAlign="left" required />}
            <ActiveFlagItem disabled={errorMsgUserWithActiveBot} />
            <Text type="danger">{errorMsgUserWithActiveBot}</Text>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

export default UserModalForm
