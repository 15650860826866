import { Col, Divider, Row, Skeleton, Button, Modal, Input, Popover, Typography } from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { EditOutlined, FormatPainterFilled, FormatPainterOutlined } from '@ant-design/icons'

import './EditActionButtons.less'
import {
  getHandlersItemStyle,
  getHandlersListStyle,
  reorderHandlers
} from '../../Helpers/dragndrop'
import { sortBy, groupBy } from '../../../../helpers'
import PresetColorPicker from '../../../../components/Common/PresetColorPicker'

const { Text } = Typography
const { confirm } = Modal
export default function EditActionButtons({ list, onChangeList, state }) {
  const inputRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const items = reorderHandlers({
        list: groupBy(list, 'appearance.y', 0),
        source: result.source,
        destination: result.destination
      })

      onChangeList?.(
        Object.values(items).reduce((prev, cur) => {
          return prev.concat(cur)
        }, [])
      )
    },
    [onChangeList, list]
  )

  const handleChangeName = useCallback(
    item => {
      setIsModalOpen(true)
      confirm({
        title: 'Изменить название',
        content: <Input ref={inputRef} defaultValue={item?.displayName} />,
        okText: 'Сохранить',
        cancelText: 'Отмена',
        onOk: () => {
          setIsModalOpen(false)
          const state = list?.find(e => e.name === item.name)
          state.displayName = inputRef?.current?.input?.value
          onChangeList?.(
            Object.values(list).reduce((prev, cur) => {
              return prev.concat(cur)
            }, []),
            item.name
          )
        },
        onCancel: () => setIsModalOpen(false)
      })
    },
    [list, onChangeList]
  )

  const handleChangeColor = useCallback(
    (key, value) => {
      const state = list?.find(e => e.name === key)
      state.appearance = state.appearance || {}
      state.appearance.color = value
      onChangeList?.(
        Object.values(list).reduce((prev, cur) => {
          return prev.concat(cur)
        }, []),
        key
      )
    },
    [list, onChangeList]
  )

  const handleChangeFill = useCallback(
    item => {
      const state = list?.find(e => e.name === item.name)
      state.appearance = state.appearance || {}
      state.appearance.fill = !state.appearance.fill
      onChangeList?.(
        Object.values(list).reduce((prev, cur) => {
          return prev.concat(cur)
        }, []),
        item.name
      )
    },
    [list, onChangeList]
  )

  const getPopoverContent = useCallback(
    item => (
      <Row>
        <Col>
          <Button
            onClick={() => handleChangeName(item)}
            disabled={isModalOpen}
            type="text"
            className="popover-btn"
          >
            <EditOutlined className="edit-name" />
          </Button>
        </Col>
        <Col className="ml-3">
          <PresetColorPicker
            disabled={isModalOpen}
            ref={inputRef}
            defaultValue={item?.appearance?.color}
            onChange={handleChangeColor}
            handler={item.name}
          />
        </Col>
        <Col className="ml-3">
          <Button
            onClick={() => handleChangeFill(item)}
            disabled={isModalOpen}
            type="text"
            className="popover-btn edit-fill"
          >
            {item?.appearance?.fill ? (
              <FormatPainterFilled style={{ color: item?.appearance?.color }} />
            ) : (
              <FormatPainterOutlined style={{ color: item?.appearance?.color }} />
            )}
          </Button>
        </Col>
      </Row>
    ),
    [handleChangeName, handleChangeColor, handleChangeFill, isModalOpen]
  )
  const renderItem = useCallback(
    (item, index) => (
      <Draggable key={item.name} draggableId={item.name} index={index}>
        {(provided, snapshot) => (
          <Popover
            content={() => getPopoverContent(item)}
            title=""
            placement="topRight"
            destroyTooltipOnHide
          >
            <Col
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getHandlersItemStyle(
                snapshot?.isDragging,
                provided.draggableProps.style,
                item.appearance
              )}
              className="draggable-item"
            >
              {item.displayName}
            </Col>
          </Popover>
        )}
      </Draggable>
    ),
    [getPopoverContent]
  )
  const renderDroppableChildren = useCallback(
    list => (provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={getHandlersListStyle(snapshot?.isDraggingOver)}
        className="droppable-list"
      >
        {list?.map(renderItem)}
        {provided.placeholder}
      </div>
    ),
    [renderItem]
  )

  return (
    <div>
      <div className="modal__wrap">
        <Row justify="space-between">
          <div className="block-title">
            <Col>АНКЕТА КАНДИДАТА</Col>
          </div>
        </Row>
        <Row>
          <Col className="mr-3">
            <Skeleton.Input size="small" />
          </Col>
          <Col className="mr-3">
            <Skeleton.Input size="small" />
          </Col>
          <Col className="mr-3">
            <Skeleton.Input size="small" />
          </Col>
        </Row>
        <Divider className="small" />
        <Row>
          <Text type="secondary">Статус:</Text>&nbsp;&nbsp;<Text strong>{state.displayName}</Text>
        </Row>
        <Row>
          <Col className="mr-3" span={9}>
            <Row>
              <Skeleton />
            </Row>
            {/*<Row>*/}
            {/*  <Skeleton />*/}
            {/*</Row>*/}
          </Col>
          <Col className="mr-3" span={14}>
            <Row>
              <Skeleton />
            </Row>
          </Col>
        </Row>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            {Object.entries(groupBy(list, 'appearance.y', 0)).map(([key, value]) => (
              <Row key={key}>
                <Droppable
                  droppableId={key}
                  type="CARD"
                  direction="horizontal"
                  // isCombineEnabled={true}
                >
                  {renderDroppableChildren(value.sort(sortBy('appearance.x')))}
                </Droppable>
              </Row>
            ))}
            <Row>
              <Droppable
                droppableId={(list?.[list?.length - 1]?.appearance?.y || 0) + 1 + ''}
                type="CARD"
                direction="horizontal"
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getHandlersListStyle(snapshot?.isDraggingOver)}
                    className="droppable-list"
                  >
                    <Button disabled>+</Button>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Row>
          </DragDropContext>
        </div>
      </div>
    </div>
  )
}
