import React from 'react'
import { Form, Select } from 'antd'

import { appConfig } from '../../../../../constants/appConfig'
import { colSettings } from '../../../../../constants'

const { Option } = Select

function DepartmentStatusItem({ disabled = false, label = 'Статус' }) {
  return (
    <Form.Item
      label={label}
      name="status"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      initialValue={appConfig.departmentStatusesKeys.opened}
    >
      <Select disabled={disabled}>
        {appConfig.departmentStatuses.map(status => (
          <Option key={status.key} value={status.key}>
            {status.value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default DepartmentStatusItem
