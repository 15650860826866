import { useMutation, useQuery } from 'react-query'
import { api } from './api'
import { USER_POSITIONS_KEY } from './userPositions'

const ONBOARDING_KEY = 'onboarding'
const mutateOptions = queryClient => ({
  onSuccess: () => {
    queryClient.refetchQueries(ONBOARDING_KEY)
    queryClient.refetchQueries(USER_POSITIONS_KEY)
    queryClient.clear()
  },
  onError: () => {
    queryClient.clear()
  }
})
const getOnboardingCheck = () => {
  return api.get({ path: `${ONBOARDING_KEY}/check` })
}

const postOnboardingSeed = () => {
  return api.post({ path: `${ONBOARDING_KEY}/seed` })
}

const postOnboardingRetrySend = () => {
  return api.post({ path: `${ONBOARDING_KEY}/resend` })
}

const deleteOnboarding = () => {
  return api.del({ path: `${ONBOARDING_KEY}/delete` })
}

export function useGetOnboardingCheck(options) {
  return useQuery([ONBOARDING_KEY], getOnboardingCheck, options)
}

export function useMutateOnboardingSeed(queryClient) {
  return useMutation(postOnboardingSeed, mutateOptions(queryClient))
}

export function useMutateOnboardingRetrySend(queryClient) {
  return useMutation(postOnboardingRetrySend, mutateOptions(queryClient))
}

export function useMutateDeleteOnboarding(queryClient) {
  return useMutation(deleteOnboarding, mutateOptions(queryClient))
}
