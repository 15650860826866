import React, { useCallback, useState } from 'react'
import { Col, Row, Typography } from 'antd'
import { DeleteOutlined, HolderOutlined } from '@ant-design/icons'

const { Paragraph, Link } = Typography

function UserFieldEditingParagraph({
  index,
  name = '',
  onEdit,
  onDelete,
  isEditing = false,
  isDeleting = false,
  maxLength = 60,
  displayName = ''
}) {
  const [editing, setEditing] = useState(isEditing)
  const handleChange = useCallback(
    value => {
      setEditing(false)
      onEdit({ index, value })
    },
    [onEdit, index]
  )

  const handleDelete = useCallback(() => {
    onDelete(index)
  }, [onDelete, index])

  const handleStart = useCallback(() => {
    setEditing(true)
  }, [])

  return (
    <Row className="list-wrapper">
      <Col>
        <HolderOutlined />
      </Col>
      <Col className="ml-3 name-item">
        <Paragraph
          editable={{
            tooltip: 'Редактировать',
            onChange: handleChange,
            editing,
            maxLength,
            onStart: handleStart
          }}
        >
          {editing ? name : displayName || name}
        </Paragraph>
      </Col>
      <Col>
        {isDeleting && (
          <Link title="Удалить" onClick={handleDelete} className="close ml-3">
            <DeleteOutlined className="red" />
          </Link>
        )}
      </Col>
    </Row>
  )
}

export default UserFieldEditingParagraph
