import React, { useMemo, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useRecoilValue } from 'recoil'

import { useRoutes } from '../../../../hooks/useRoutes'
import {
  PASSWORD_RECOVERY,
  FORGOT_PASSWORD,
  EXTERNAL_BUTTON,
  PLANNING_PERIOD_PAGE,
  PLANNING_DEPARTMENTS_PAGE
} from '../../../../constants/routes'
import { modules } from '../../../../constants'
import LinkWrapper from '../../../../components/LinkWrapper'
import { UserContext } from '../../../../contexts/userContext'
import { ConfigContext } from '../../../../contexts/configContext'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'

const { Sider } = Layout
const noSidebarPathes = ['/', PASSWORD_RECOVERY, FORGOT_PASSWORD, EXTERNAL_BUTTON]

const Sidebar = ({ collapsed }) => {
  // Don't show on Auth page
  const { pathname } = useLocation()
  const { user } = useContext(UserContext)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const { menuTheme } = useRecoilValue(customPropertiesSettingsAtom)

  const [openKeys, setOpenKeys] = useState([])
  const { routes } = useRoutes(user, modules.massRecruitment)

  const selectedKeys = useMemo(() => {
    let name = `/${pathname.split('/')?.[1]}`
    if (name === PLANNING_PERIOD_PAGE) {
      name = PLANNING_DEPARTMENTS_PAGE
    }
    return name
  }, [pathname])

  useEffect(() => {
    let name = `/${pathname.split('/')?.[1]}`

    const isDirectRoute = routes?.find(r => r.path === name)
    if (!isDirectRoute) {
      routes?.map(r => {
        if (r?.children?.find(r => r.path === name)) {
          setOpenKeys([r.name])
        }
      })
    } else {
      setOpenKeys([])
    }
  }, [pathname, routes])

  const filteredRoutes = useMemo(
    () =>
      routes
        ?.filter(route => route.name)
        ?.filter(route =>
          route.featureToggle ? features?.some(feature => route.featureToggle === feature) : true
        )
        // если есть дети то фильтруем детей
        ?.map(route => {
          if (route.children) {
            route.children = route.children
              ?.filter(child => child.name)
              ?.filter(child =>
                child.featureToggle
                  ? features?.some(feature => child.featureToggle === feature)
                  : true
              )
          }
          return route
        }),
    //фильтровать родителей без детей
    [features, routes]
  )

  const menuItems = useMemo(() => {
    const getItems = route => {
      const linkProps = route.useHtmlLink ? { href: route.path } : { to: route.path }
      return {
        label: route?.children ? (
          <div>{route.name}</div>
        ) : (
          <LinkWrapper {...linkProps}>{route.name}</LinkWrapper>
        ),
        key: route.path || route.name,
        children: route.children?.map(getItems)
      }
    }
    return filteredRoutes?.map(getItems)
  }, [filteredRoutes])

  if (noSidebarPathes.includes(pathname)) return null

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      width={250}
      theme={menuTheme}
    >
      <div className="sidebar-logo">
        <div className="logo" />
      </div>
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        theme={menuTheme}
        items={menuItems}
        onOpenChange={keys => setOpenKeys(keys)}
      />
    </Sider>
  )
}
export default Sidebar

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired
}
