import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography, Spin, message, Button, Row, Divider, Table, Modal } from 'antd'
import { Link } from 'react-router-dom'
import QRCode from 'react-qr-code'

import { withFeatureToggle } from '../../../../components/Common/FeatureToggle'
import { appConfig } from '../../../../constants/appConfig'
import {
  useGetTelegramStatus,
  useGetTelegramLink,
  useGetTelegramUnlink
} from '../../../../api/integrations'
import './Telegram.less'
import { useQueryClient } from 'react-query'

const { Title } = Typography
const TELEGRAM_BOT_STATUSES = {
  available: 'available',
  unavailable: 'unavailable',
  waiting: 'waiting',
  linked: 'linked'
}
function Telegram() {
  const [linkForQR, setLinkForQR] = useState('')
  const queryClient = useQueryClient()
  const { data: dataTelegramBots, isLoading: isLoadingTelegramBots } = useGetTelegramStatus({
    retry: 0
  })
  const bots = useMemo(() => dataTelegramBots?.data, [dataTelegramBots?.data])

  const {
    mutate: mutateTelegramLink,
    isSuccess: isSuccessTelegramLink,
    isError: isErrorTelegramLink,
    isLoading: isLoadingTelegramLink
  } = useGetTelegramLink(queryClient)
  useEffect(() => {
    if (isSuccessTelegramLink) {
      message.info('Телеграм бот подключен успешно')
    } else if (isErrorTelegramLink) {
      message.error('Ошибка подключения телеграм бота')
    }
  }, [isSuccessTelegramLink, isErrorTelegramLink])

  const {
    mutate: mutateTelegramUnlink,
    isSuccess: isSuccessTelegramUnlink,
    isError: isErrorTelegramUnlink,
    isLoading: isLoadingTelegramUnlink
  } = useGetTelegramUnlink(queryClient)
  useEffect(() => {
    if (isSuccessTelegramUnlink) {
      message.info('Телеграм бот отключен успешно')
    } else if (isErrorTelegramUnlink) {
      message.error('Ошибка отключения телеграм бота')
    }
  }, [isSuccessTelegramUnlink, isErrorTelegramUnlink])

  const handleLink = useCallback(
    botName => {
      mutateTelegramLink({ botName })
    },
    [mutateTelegramLink]
  )
  const handleUnlink = useCallback(
    botName => {
      mutateTelegramUnlink({ botName })
    },
    [mutateTelegramUnlink]
  )

  const statuses = useMemo(
    () => ({
      available: {
        name: TELEGRAM_BOT_STATUSES.available,
        displayName: 'Доступен',
        action: ({ name }) => (
          <Button type="primary" onClick={() => handleLink(name)}>
            Подключить
          </Button>
        )
      },
      unavailable: {
        name: TELEGRAM_BOT_STATUSES.available,
        displayName: 'Недоступен',
        action: () => <></>
      },
      waiting: {
        name: TELEGRAM_BOT_STATUSES.available,
        displayName: 'Ожидание',
        action: ({ link }) => (
          <>
            <Link to={link} target="_blank" rel="opener noreferrer">
              Перейдите в бот и нажмите кнопку &quot;Start&quot;
            </Link>
            &nbsp;или&nbsp;
            <Link onClick={() => setLinkForQR(link)}>отсканируйте QR-код</Link>
          </>
        )
      },
      linked: {
        name: TELEGRAM_BOT_STATUSES.available,
        displayName: 'Привязан',
        action: ({ name }) => (
          <Button type="primary" onClick={() => handleUnlink(name)}>
            Отключить
          </Button>
        )
      }
    }),
    [handleLink, handleUnlink]
  )
  const columns = useMemo(
    () => [
      {
        title: 'Имя',
        dataIndex: 'name'
      },
      {
        title: 'Статус',
        width: 170,
        dataIndex: 'status',
        render: status => <Row>{statuses[status].displayName}</Row>
      },
      {
        title: 'Действие',
        dataIndex: 'action',
        render: (value, item) => <Row>{statuses[item.status].action(item)}</Row>
      }
    ],
    [statuses]
  )
  const getRowClassName = useCallback(
    record => (record.status === TELEGRAM_BOT_STATUSES.unavailable ? 'unavailable-row' : ''),
    []
  )

  if (!bots?.length) return null
  return (
    <div className="mt-3">
      <Divider />
      <Title level={5}>Telegram уведомления</Title>
      <Spin spinning={isLoadingTelegramBots || isLoadingTelegramLink || isLoadingTelegramUnlink}>
        <Table
          size="middle"
          columns={columns}
          rowKey="name"
          dataSource={bots}
          rowClassName={getRowClassName}
          pagination={false}
        />
      </Spin>
      <Modal
        width={304}
        closable={false}
        open={linkForQR}
        onCancel={() => setLinkForQR('')}
        cancelText="Закрыть"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <QRCode value={linkForQR} viewBox={`0 0 256 256`} />
      </Modal>
    </div>
  )
}

export default withFeatureToggle(Telegram, appConfig.features.telegram)
