import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Col, Divider, Input, Row, Spin, Typography, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import UsersCheckList from './UsersCheckList'
import { langCases } from '../../../../constants/appConfig'
// import './UserRelationList.less'
import { EditingTitle } from '../index'
import { OrgStructureContext } from '../../../../contexts/orgStructureContext'

const { Title } = Typography
const { Search } = Input

function UserRelationList({
  onSelectAll,
  onSave,
  onCancel,
  isLoading = false,
  title = '',
  enabledUserEdit = false,
  onEditOrgUnitUser,
  lastSelectedUnitChildrenIds,
  onChangeSearch,
  onSelectUser,
  enabledSelectRelatedUsers = false,
  onSelectRelatedUser,
  enabledSettingUser = false,
  enabledEditTitle = false,
  onSettingUser,
  departments,
  otherUsers,
  relatedUsers,
  selectedUsers,
  search,
  selectedRelatedUsers,
  selectedUnits
}) {
  const [isForceLoading, setIsForceLoading] = useState(true)
  const { getDepartmentSingularName, getDepartmentPluralName } = useConfigContextData()
  const orgStructure = useContext(OrgStructureContext)
  const [form] = Form.useForm()

  const handleSelectAll = useCallback(() => onSelectAll?.(), [onSelectAll])
  const handleCancel = useCallback(() => {
    onCancel?.()
    form.resetFields()
  }, [onCancel, form])
  const handleSave = useCallback(() => form.validateFields().then(() => onSave?.()), [onSave, form])
  const handleChangeSearch = useCallback(event => onChangeSearch?.(event), [onChangeSearch])

  useEffect(() => {
    form.setFieldValue('name', title)
  }, [form, title])

  useEffect(() => {
    const timer = setTimeout(() => setIsForceLoading(false), 0)
    return () => clearTimeout(timer)
  })

  const departmentName = useMemo(() => {
    const str = '' + departments?.length
    return str[str.length - 1] === '1'
      ? getDepartmentSingularName(langCases.genitive)
      : getDepartmentPluralName(langCases.genitive)
  }, [departments, getDepartmentSingularName, getDepartmentPluralName])

  const span = useMemo(() => (enabledSelectRelatedUsers ? 15 : null), [enabledSelectRelatedUsers])
  const getHighlightText = useCallback(
    text => {
      // const strTitle = text
      const index = text.toLowerCase().indexOf(search.toLowerCase())
      const beforeStr = text.substring(0, index)
      const afterStr = text.slice(index + search.length)
      // if (item.children) {
      //   return {
      //     ...item,
      //     name,
      //     children: loop(item.children)
      //   }
      // }

      return index > -1 ? (
        <span>
          {beforeStr}
          <span className="tree-search-value">{text.substring(index, index + search.length)}</span>
          {afterStr}
        </span>
      ) : (
        <span>{text}</span>
      )
    },
    [search]
  )
  const handleCheckRelatedUsers = useCallback(
    userId => selectedRelatedUsers?.find(e => e._id === userId),
    [selectedRelatedUsers]
  )
  const handleCheckOtherUsers = useCallback(
    userId => selectedUsers?.find(e => e._id === userId),
    [selectedUsers]
  )
  const getText = useCallback(
    text => (search ? getHighlightText(text) : text),
    [search, getHighlightText]
  )

  const relatedUsersNeedRightBlock = useCallback(() => enabledUserEdit, [enabledUserEdit])
  const relatedUsersRightBlockText = useCallback(
    user => (
      <>
        {
          user.scope?.departmentReport?.filter(departmentId =>
            lastSelectedUnitChildrenIds?.includes(departmentId)
          )?.length
        }{' '}
        из {departments?.length} {departmentName?.toLowerCase()}
      </>
    ),
    [departments, departmentName, lastSelectedUnitChildrenIds]
  )

  const otherUsersNeedRightBlock = useCallback(
    userId => enabledSettingUser && selectedUsers?.find(e => e._id === userId),
    [enabledSettingUser, selectedUsers]
  )
  const otherUsersRightBlockText = useCallback(
    user => (
      <>
        {selectedUsers?.find(e => e._id === user._id)?.scope?.newDepartments?.length} из{' '}
        {departments?.length} {departmentName?.toLowerCase()}
      </>
    ),
    [departments, departmentName, selectedUsers]
  )

  const handleSaveTitle = useCallback(
    value => {
      form.setFieldsValue({ name: value.trim() })
      form.validateFields()
      if (!value?.trim()) return
      const setUnit = (data, layerIndex = 0) => {
        if (layerIndex === selectedUnits.length) {
          data.name = value
          data.deleted = false
          return null
        }

        const unit = data?.children?.find(item => item._id === selectedUnits[layerIndex]._id)
        setUnit(unit, layerIndex + 1)
      }

      const units = { children: [...orgStructure.units] }
      setUnit(units)
    },
    [orgStructure, selectedUnits, form]
  )
  return (
    <div className="drawer__wrap">
      <Form scrollToFirstError form={form} className="drawer-content">
        <div>
          <div className="drawer-header">
            {enabledEditTitle ? (
              <div className="block-title no-gutters">
                <Form.Item name="name" rules={[{ required: true, message: 'Укажите название' }]}>
                  <EditingTitle
                    displayName={title}
                    onEdit={handleSaveTitle}
                    isResetEditing={isLoading}
                  />
                </Form.Item>
              </div>
            ) : (
              <Row justify="space-between" align="large">
                <div className="block-title no-gutters">{title}</div>
              </Row>
            )}
            <Divider className="small" />
            <Row>
              <Search
                size="large"
                rootClassName="without-addon org-search custom-search"
                placeholder="Поиск пользователя"
                onChange={handleChangeSearch}
                allowClear
                prefix={<SearchOutlined />}
              />
            </Row>
          </div>
          <div className="user-list">
            <UsersCheckList
              list={relatedUsers}
              title="Пользователи"
              span={span}
              needCheckbox={enabledSelectRelatedUsers}
              onSelectRow={onSelectRelatedUser}
              onCheckedRow={handleCheckRelatedUsers}
              getText={getText}
              noUserText="Не назначено"
              onNeedRightBlock={relatedUsersNeedRightBlock}
              onClickRightBlock={onEditOrgUnitUser}
              getRightBlockText={relatedUsersRightBlockText}
            />
            {isForceLoading ? (
              <Spin>
                <Title className="mt-3 title" level={4}>
                  Добавить пользователя
                </Title>
              </Spin>
            ) : (
              <UsersCheckList
                list={otherUsers}
                title="Добавить пользователя"
                // span={span}
                needCheckbox
                onSelectRow={onSelectUser}
                onCheckedRow={handleCheckOtherUsers}
                getText={getText}
                noUserText="Нет свободных пользователей"
                onNeedRightBlock={otherUsersNeedRightBlock}
                onClickRightBlock={onSettingUser}
                getRightBlockText={otherUsersRightBlockText}
                subBlock={
                  <Row align="middle" onClick={handleSelectAll} className="select-all">
                    <Col>
                      <Checkbox
                        className="mr-3"
                        checked={selectedUsers.length === otherUsers?.length}
                      />
                    </Col>
                    <Col>Выбрать всех</Col>
                  </Row>
                }
              />
            )}
          </div>
        </div>
        <div className="drawer-footer">
          <Divider className="small" />
          <Row className="buttons">
            <Col>
              <Button type="link" className="mr-3" onClick={handleCancel} disabled={isLoading}>
                Отмена
              </Button>
              <Button type="primary" onClick={handleSave} loading={isLoading}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}

export default UserRelationList
