import React, { useContext, Suspense, useMemo, useCallback } from 'react'
import { Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import { useRoutes } from './hooks/useRoutes'
import { UserContext } from './contexts/userContext'
import { noAuthPrefix } from './constants'
import { ErrorLayout } from './modules/massRecruitment/Screens/ErrorLayout/ErrorLayout'

function Routes({ module }) {
  const { user } = useContext(UserContext)
  const { routes } = useRoutes(user, module)
  const { pathname } = useLocation()
  const isUserLogged = useMemo(() => !!Object.keys(user).length, [user])

  const getRedirect = useCallback(() => {
    /**
     * we add redirect to user for prevent routes not for his role
     * and for unregister (without token) for redirect to Auth
     */
    return (routes || !isUserLogged) && <Route path="*" element={<Navigate to="/" replace />} />
  }, [isUserLogged, routes])

  return (
    <Suspense
      fallback={
        !pathname.includes(noAuthPrefix) ? (
          <Spin spinning size="large" className="full-screen-spin suspense" />
        ) : (
          <div />
        )
      }
    >
      <ReactRoutes>
        {/* ExternalButton routes: Should be FIRST because contain own simple auth page */}
        {/*<Route path={EXTERNAL_BUTTON} element={<ExternalMain />} />*/}
        {/*<Route path={EXTERNAL_BUTTON_CANDIDATE_PAGE} element={<ExternalCandidateEdit />} />*/}
        {/* End ExternalButton routes */}
        {/*<Route path="/" element={<Auth />} />*/}
        {/*<Route path={PASSWORD_RECOVERY} element={<PasswordRecovery />} />*/}
        {/*<Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />*/}
        {/*<Route path={NO_AUTH_CANDIDATE_PAGE} element={<NoAuthCandidate />} />*/}
        {routes?.map(({ path, component: Component, children }) =>
          Component ? (
            <Route key={path} path={path} element={<Component />} errorElement={<ErrorLayout />} />
          ) : (
            children?.map(({ path: childPath, component: ChildComponent }) => (
              <Route
                key={childPath}
                path={childPath}
                element={<ChildComponent />}
                errorElement={<ErrorLayout />}
              />
            ))
          )
        )}
        {getRedirect()}
      </ReactRoutes>
    </Suspense>
  )
}
export default Routes
