import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Typography, Spin, message, Row, Checkbox, Button, Divider } from 'antd'

import { useMutateSettingsByCode } from '../../../../api/setting'
import { appConfig } from '../../../../constants/appConfig'
import { ConfigContext } from '../../../../contexts/configContext'
import { DraggableList } from '../DraggableList/DraggableList'
import './SortableStates.less'
import { withFeatureToggle } from '../../../../components/Common/FeatureToggle'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { sortBy } from '../../../../helpers'
import { useQueryClient } from 'react-query'

const { Title, Text } = Typography

function SortableStates({ filterMode = false, withoutEndDivider = false, onChange, list }) {
  const queryClient = useQueryClient()
  const {
    settings: { data: settingsData }
  } = useContext(ConfigContext)
  const [activeWorkflowStates, setActiveWorkflowStates] = useState([])
  const [inactiveWorkflowStates, setInactiveWorkflowStates] = useState([])
  const { getCandidateWorkflowStateWithLabelByName, getWorkflowStatesWithLabelsByName } =
    useConfigContextData()

  const prepareStatusList = useCallback(
    status => {
      const item = getCandidateWorkflowStateWithLabelByName(status)
      return {
        id: item?.key || status,
        content: item?.label || status
      }
    },
    [getCandidateWorkflowStateWithLabelByName]
  )

  const {
    mutate: saveSettingByCode,
    isSuccess: isSuccessSet,
    isError: isErrorSet,
    isLoading: isLoadingSet
  } = useMutateSettingsByCode(queryClient)

  const handleSave = () => {
    saveSettingByCode({
      code: appConfig.settings.sortableStates,
      value: JSON.stringify(activeWorkflowStates?.map(e => e.id))
    })
  }

  useEffect(() => {
    if (isSuccessSet) {
      message.info('Настройка сохранена')
    } else if (isErrorSet) {
      message.error('Ошибка сохранения настроек')
    }
  }, [isErrorSet, isSuccessSet])

  useEffect(() => {
    // сначала берем локально сохраненные статусы (переданные в аргументах)
    // если статусы для сортироовки еще не сохранялись в "localStorage" то берем их из Workflow
    const activeStatuses =
      list ||
      getWorkflowStatesWithLabelsByName(appConfig.workflows.candidate.name)
        ?.filter(s => s.showInDashboards)
        ?.sort(sortBy('sortOrder'))
        ?.map(s => s.key)

    const inactiveList = getWorkflowStatesWithLabelsByName(appConfig.workflows.candidate.name)
      ?.sort(sortBy('sortOrder'))
      ?.map(s => s.key)
      ?.filter(e => !activeStatuses?.includes(e))
      ?.map(prepareStatusList)

    setInactiveWorkflowStates(inactiveList)
    setActiveWorkflowStates(activeStatuses?.map(prepareStatusList))
  }, [list, settingsData, getWorkflowStatesWithLabelsByName, prepareStatusList])

  const handleSetActive = item => {
    setInactiveWorkflowStates(inactiveWorkflowStates?.filter(e => e?.id !== item?.id))
    setActiveWorkflowStates([...activeWorkflowStates, item])
    onChange && onChange([...activeWorkflowStates, item])
  }

  const handleSetInactive = useCallback(
    item => {
      const activeList = activeWorkflowStates?.filter(e => e?.id !== item?.id)
      setInactiveWorkflowStates([...inactiveWorkflowStates, item])
      setActiveWorkflowStates(activeList)
      onChange && onChange(activeList)
    },
    [onChange, activeWorkflowStates, inactiveWorkflowStates]
  )

  const handleChangeList = useCallback(
    list => {
      setActiveWorkflowStates(list)
      onChange && onChange(list)
    },
    [onChange]
  )
  const onRenderContent = useCallback(
    item => (
      <Row key={item?.id} className="active-status">
        <Checkbox checked onChange={() => handleSetInactive(item)} />
        {item?.content}
      </Row>
    ),
    [handleSetInactive]
  )

  return (
    <div className="mt-3">
      {!filterMode && <Title level={5}>Порядок статусов для блока Аналитика по умолчанию</Title>}
      <Text type="secondary">
        Выберите и расположите в нужном порядке статусы кандидатов для отображения на графике
        &quot;Статусы кандидатов&quot;.
      </Text>
      <Spin spinning={isLoadingSet}>
        {activeWorkflowStates.length > 0 && (
          <DraggableList
            list={activeWorkflowStates}
            onChangeList={handleChangeList}
            onRenderContent={onRenderContent}
          />
        )}
        <Divider className="small" />
        <Text type="secondary">
          Статусы кандидатов, которые не будут отображаться на графике &quot;Статусы
          кандидатов&quot;.
        </Text>
        {inactiveWorkflowStates.length > 0 &&
          inactiveWorkflowStates.map(item => (
            <Row key={item?.id} className="inactive-status">
              <Checkbox onChange={() => handleSetActive(item)} />
              {item?.content}
            </Row>
          ))}
        {!filterMode && (
          <Row>
            <Button type="primary" className="mt-3" onClick={handleSave} disabled={isLoadingSet}>
              Сохранить
            </Button>
          </Row>
        )}
      </Spin>
      {!withoutEndDivider && <Divider />}
    </div>
  )
}

export default withFeatureToggle(SortableStates, appConfig.features.analytics)
