import React, { useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Row } from 'antd'

import { getItemStyle, getListStyle, reorder } from '../../Helpers/dragndrop'

/**
 * @param {Array} list [{ id: String, content: String }]
 */
export const DraggableList = ({ list, onChangeList, onRenderContent }) => {
  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const items = reorder(list, result.source.index, result.destination.index)

      onChangeList(items)
    },
    [onChangeList, list]
  )
  const renderItem = useCallback(
    (item, index) => (
      <Draggable key={item?.id || index} draggableId={item?.id || index.toString()} index={index}>
        {(provided, snapshot) => (
          <Row
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot?.isDragging, provided.draggableProps.style)}
            className="draggable-item"
          >
            {onRenderContent ? onRenderContent(item, index) : item?.content}
          </Row>
        )}
      </Draggable>
    ),
    [onRenderContent]
  )
  const renderDroppableChildren = useCallback(
    (provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={getListStyle(snapshot?.isDraggingOver)}
        className="droppable-list"
      >
        {list?.map(renderItem)}
        {provided.placeholder}
      </div>
    ),
    [list, renderItem]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">{renderDroppableChildren}</Droppable>
    </DragDropContext>
  )
}
