import React, { useEffect } from 'react'
import { Form, Input, Button, message, Spin, Typography } from 'antd'
import { useMutateLogin } from '../../api/auth'
import { FORGOT_PASSWORD } from '../../constants/routes'
import { useQueryClient } from 'react-query'

const { Title } = Typography

const defaultLayout = {
  wrapperCol: { span: 12 }
}

function AuthForm({ onSuccess, titleClassName = 'title', layout = {}, isLinkInNewWindow = false }) {
  const queryClient = useQueryClient()
  /**
   * Login
   */
  const { mutate, status, isLoading } = useMutateLogin(queryClient)
  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (status === 'error') {
      message.error('Неверный логин/пароль')
    }
  }, [status])

  const onFinish = ({ login, password }) => mutate({ login, password }, { onSuccess })

  return (
    <Spin spinning={isLoading} size="large">
      <Form {...{ ...defaultLayout, ...layout }} name="basic" onFinish={onFinish}>
        <Title level={4} className={titleClassName}>
          Авторизация
        </Title>
        <Form.Item
          className="auth-input-margin justify-center"
          name="login"
          rules={[{ required: true, message: 'Пожалуйста введите ваш логин!' }]}
        >
          <Input size="large" placeholder="логин" />
        </Form.Item>

        <Form.Item
          className="auth-input-margin justify-center"
          name="password"
          rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
        >
          <Input.Password size="large" placeholder="пароль" />
        </Form.Item>

        <Form.Item className="auth-input-margin justify-center">
          <div className="link text-center">
            <a
              href={FORGOT_PASSWORD}
              target={isLinkInNewWindow ? '_blank' : '_self'}
              rel="noreferrer"
            >
              <u>Восстановить пароль</u>
            </a>
          </div>
        </Form.Item>

        <Form.Item {...layout} className="auth-input-margin justify-center">
          <Button type="primary" htmlType="submit" block size="large">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default AuthForm
