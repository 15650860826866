import { useMutation, useQuery } from 'react-query'
import { api } from './api'
import { defaultQueryOptions } from '../constants'

export const REFS_KEY = 'references'
const TREE_KEY = 'tree'

const fetchGetRefsByCode = ({ queryKey }) => {
  const [, /* key */ code] = queryKey
  return api.get({ path: 'references/' + code })
}

const fetchGetUtmTree = () => {
  return api.get({ path: 'references/utm/tree' })
}

const fetchDeleteUtm = id => {
  return api.del({ path: `references/utm/${id}` })
}

const fetchPatchUtm = requestType => {
  return api.patch({ path: `references/utm/${requestType?._id}`, data: requestType })
}

const fetchPutUtm = data => {
  return api.put({ path: `references/utm/`, data })
}
/**
 * By default reference lists will be invariable so we keep they in cache without refetch
 */
export function useRefsByCode(code, options = {}) {
  return useQuery([REFS_KEY, code], fetchGetRefsByCode, { ...defaultQueryOptions, ...options })
}

export function useUtmTree(options = {}) {
  return useQuery([TREE_KEY], fetchGetUtmTree, options)
}

export function usePatchUtmTree(queryClient) {
  return useMutation(fetchPatchUtm, {
    onSuccess: () => {
      queryClient.refetchQueries(TREE_KEY)
    }
  })
}

export function useDeleteUtmTree(queryClient) {
  return useMutation(fetchDeleteUtm, {
    onSuccess: () => {
      queryClient.refetchQueries(TREE_KEY)
    }
  })
}
export function usePutUtmTree(queryClient) {
  return useMutation(fetchPutUtm, {
    onSuccess: () => {
      queryClient.refetchQueries(TREE_KEY)
    }
  })
}
