import { useMutation, useQuery } from 'react-query'
import { api } from './api'
import { DEPARTMENT_KEY, DEPARTMENT_SEARCH_KEY } from './department'

export const AGENCY_KEY = 'agency'
const AGENCIES_PREFIX = 'agencies'

const fetchGetAgencies = ({ queryKey }) => {
  const [, /* key */ params] = queryKey
  return api.get({ path: AGENCIES_PREFIX, params })
}

const putCreateAgency = ({ data }) => {
  return api.put({ path: AGENCIES_PREFIX, data })
}

const fetchGetAgencyById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  if (!id) return null
  return api.get({ path: AGENCIES_PREFIX + '/' + id })
}

const patchAgency = ({ id, data }) => {
  return api.patch({ path: `${AGENCIES_PREFIX}/${id}`, data })
}

const postAgencyApiKeyMail = ({ id }) => {
  return api.post({ path: `${AGENCIES_PREFIX}/${id}/sendEmail` })
}

export function useMutateEditAgency(queryClient) {
  return useMutation(patchAgency, {
    onSuccess: () => {
      queryClient.refetchQueries(AGENCY_KEY, { active: true })
      queryClient.invalidateQueries(AGENCY_KEY)
      queryClient.invalidateQueries(DEPARTMENT_KEY)
      queryClient.invalidateQueries(DEPARTMENT_SEARCH_KEY)
    }
  })
}

export function useMutateCreateAgency(queryClient) {
  return useMutation(putCreateAgency, {
    onSuccess: () => {
      queryClient.refetchQueries(AGENCY_KEY, { active: true })
      queryClient.invalidateQueries(AGENCY_KEY)
      queryClient.invalidateQueries(DEPARTMENT_KEY)
      queryClient.invalidateQueries(DEPARTMENT_SEARCH_KEY)
    }
  })
}

export function useMutationSendApiKeyAgency() {
  return useMutation(postAgencyApiKeyMail)
}

export function useAgenciesQuery(params, options) {
  return useQuery([AGENCY_KEY, params], fetchGetAgencies, options)
}

export function useAgencyQuery(id, options) {
  return useQuery([AGENCY_KEY, id], fetchGetAgencyById, options)
}
