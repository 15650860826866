import React, { useCallback, useEffect } from 'react'
import { Button, message, Modal } from 'antd'
import { useQueryClient } from 'react-query'

import { useMutateApprovePlan, useMutateRejectPlan } from '../../../../api/flow/plan'

const { confirm } = Modal

function ManagerButtons({
  plan,
  planForm = null,
  needSave = false,
  onSave,
  isNewPlan,
  isLoading = false,
  wasFormEdited = false
}) {
  const queryClient = useQueryClient()
  const {
    mutate: mutateApprove,
    isLoading: isLoadingApprove,
    isSuccess: isSuccessApprove,
    isError: isErrorApprove
  } = useMutateApprovePlan(queryClient)
  const {
    mutate: mutateReject,
    isLoading: isLoadingReject,
    isSuccess: isSuccessReject,
    isError: isErrorReject
  } = useMutateRejectPlan(queryClient)

  useEffect(() => {
    if (isErrorApprove || isErrorReject) {
      message.error('Ошибка изменения статуса планирования')
    }
  }, [isErrorApprove, isErrorReject])

  useEffect(() => {
    if (isSuccessApprove) {
      message.success('План успешно утвержден')
    }
    if (isSuccessReject) {
      message.success('План успешно отклонен')
    }
  }, [isSuccessReject, isSuccessApprove])

  const approvePlan = useCallback(async () => {
    confirm({
      title: 'Утвердить планирование?',
      content: 'Отменить действие будет нельзя',
      okText: 'Утвердить',
      cancelText: 'Отмена',
      onOk: async () => {
        const mutateFn = async () =>
          await mutateApprove({
            ...planForm?.getFieldsValue(['comment']),
            planId: plan?._id
          })
        if (needSave) {
          onSave?.({ cb: mutateFn })
        } else {
          await mutateFn()
        }
      }
    })
  }, [needSave, plan, planForm, mutateApprove, onSave])

  const rejectPlan = useCallback(async () => {
    confirm({
      title: 'Отклонить планирование?',
      content: 'Отменить действие будет нельзя',
      okText: 'Отклонить',
      cancelText: 'Отмена',
      onOk: async () => {
        await mutateReject({
          ...planForm?.getFieldsValue(['comment']),
          planId: plan?._id
        })
      }
    })
  }, [mutateReject, plan, planForm])

  const handleActionPLan = useCallback(
    name => {
      if (isNewPlan || name === 'createOrUpdate') {
        return onSave()
      }
      if (name === 'reject') {
        return rejectPlan()
      }
      if (name === 'approve') {
        return approvePlan()
      }
    },
    [isNewPlan, onSave, rejectPlan, approvePlan]
  )

  return (
    <>
      <div className="btn-wrap mt-3">
        {isNewPlan && (
          <Button
            type="primary"
            className="green"
            size="large"
            onClick={handleActionPLan}
            disabled={!wasFormEdited}
            loading={isLoading}
          >
            Сохранить
          </Button>
        )}
        {plan?.possibleActions?.map(action => (
          <Button
            key={action.name}
            type={action.appearance?.color && 'primary'}
            className={action.appearance?.color}
            size="large"
            disabled={!wasFormEdited && action.name === 'createOrUpdate'}
            loading={isLoading || isLoadingReject || isLoadingApprove}
            onClick={() => handleActionPLan(action.name)}
          >
            {action.displayName}
          </Button>
        ))}
      </div>
    </>
  )
}

export default ManagerButtons
