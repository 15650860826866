import './wdyr'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot, useRecoilSnapshot } from 'recoil'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { queryClient } from './api/api'
import { ErrorLayout } from './modules/massRecruitment/Screens/ErrorLayout/ErrorLayout'
import './helpers/sentry/init'

function DebugObserver() {
  const snapshot = useRecoilSnapshot()
  useEffect(() => {
    console.debug('The following atoms were modified:')
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node))
    }
  }, [snapshot])

  return null
}
function Root(props) {
  return (
    <QueryClientProvider client={props?.queryClient?.() || queryClient}>
      <RecoilRoot>
        <DebugObserver />
        <Sentry.ErrorBoundary fallback={<ErrorLayout />}>
          <App customProperties={props} />
        </Sentry.ErrorBoundary>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
// export default Root
export default Sentry.withProfiler(Root, { name: 'CustomAppName' })
