import { api } from '../api'
import { useMutation, useQuery } from 'react-query'
import {
  CANDIDATE_KEY,
  CANDIDATES_KEY,
  CANDIDATES_SEARCH_KEY,
  CANDIDATES_STATES_KEY,
  CANDIDATES_FILTER_VALUES_KEY,
  CANDIDATES_MINE,
  CANDIDATES_FIND_SIMILAR,
  CANDIDATES_CONFLICTS_KEY
} from '../candidate'
import { APPOINTMENTS_KEY, APPOINTMENTS_USER_KEY } from '../appointment'
import {
  CANDIDATE_STATUSES_KEY,
  CURRENT_MONTH_INTERVIEWS_KEY,
  INTERVIEWS_KEY,
  LEADS_KEY,
  REJECT_REASONS_KEY,
  REQUIREMENT_KEY,
  SOURCES_AND_STATUSES_KEY
} from '../dashboard'
import { AMP_KEY } from '../amp'
import { PLAN_KEY, PLANS_KEY } from '../plan'

const WORKFLOW_STATES_KEY = 'workflowStates'

const onSuccessMutateCandidateFlow = queryClient => () => {
  // update lists for hiringManager and recruiter
  queryClient.refetchQueries(CANDIDATE_KEY, { active: true })
  queryClient.invalidateQueries(CANDIDATE_KEY)
  queryClient.refetchQueries(CANDIDATES_KEY)
  queryClient.refetchQueries(CANDIDATES_MINE)
  queryClient.refetchQueries(CANDIDATES_STATES_KEY, { active: true })
  queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
  queryClient.refetchQueries(CANDIDATES_FILTER_VALUES_KEY, { active: true })
  queryClient.invalidateQueries(PLANS_KEY)
  queryClient.invalidateQueries(PLAN_KEY, { active: true })

  queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
  queryClient.invalidateQueries(AMP_KEY)
  queryClient.refetchQueries(AMP_KEY, { active: true })
  queryClient.refetchQueries(APPOINTMENTS_USER_KEY, { active: true })

  // при переводе кандидата по статусам сбрасываем кэш аналитики
  queryClient.invalidateQueries(REQUIREMENT_KEY)
  queryClient.invalidateQueries(INTERVIEWS_KEY)
  queryClient.invalidateQueries(LEADS_KEY)
  queryClient.invalidateQueries(CURRENT_MONTH_INTERVIEWS_KEY)
  queryClient.invalidateQueries(REJECT_REASONS_KEY)
  queryClient.invalidateQueries(CANDIDATE_STATUSES_KEY)
  queryClient.invalidateQueries(SOURCES_AND_STATUSES_KEY)
  queryClient.refetchQueries(CANDIDATES_FIND_SIMILAR)
  queryClient.invalidateQueries(CANDIDATES_CONFLICTS_KEY)
}

const fetchFlowAction = ({ path, data }) => {
  return api.post({ path, data })
}
const fetchWorkflowStates = () => {
  return api.get({ path: 'workflow/candidateStates' })
}
const fetchReuseCandidateById = ({ id, data }) => {
  return api.post({ path: 'flow/candidate/reuse', data: { ...data, candidateId: id } })
}

export function useQueryWorkflowStates(options) {
  return useQuery([WORKFLOW_STATES_KEY], fetchWorkflowStates, options)
}
export function useMutateFlowAction(queryClient) {
  return useMutation(fetchFlowAction, {
    onSuccess: onSuccessMutateCandidateFlow(queryClient)
  })
}
export function useMutateFlowReuseCandidate(queryClient) {
  return useMutation(fetchReuseCandidateById, {
    onSuccess: onSuccessMutateCandidateFlow(queryClient)
  })
}
