import { useMutation } from 'react-query'
import { api } from '../api'
import { PLAN_KEY, PLANS_KEY } from '../plan'

const putPlanComment = ({ id, data }) => {
  return api.put({ path: `plans/${id}/comments`, data })
}

const deletePlanComment = ({ id, commentId }) => {
  return api.del({ path: `plans/${id}/comments/${commentId}` })
}

const patchPlanComment = ({ id, commentId, data }) => {
  return api.patch({ path: `plans/${id}/comments/${commentId}`, data })
}

const onSuccessMutatePlanComment = queryClient => () => {
  queryClient.refetchQueries(PLAN_KEY, { active: true })
  queryClient.refetchQueries(PLANS_KEY)
}

export function useMutateCreatePlanComment(queryClient) {
  return useMutation(putPlanComment, {
    onSuccess: onSuccessMutatePlanComment(queryClient)
  })
}

export function useMutateDeletePlanComment(queryClient) {
  return useMutation(deletePlanComment, {
    onSuccess: onSuccessMutatePlanComment(queryClient)
  })
}

export function useMutateUpdatePlanComment(queryClient) {
  return useMutation(patchPlanComment, {
    onSuccess: onSuccessMutatePlanComment(queryClient)
  })
}
