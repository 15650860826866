import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { ConfigProvider, message, Spin, Table, Typography, Button, Input, Col, Row } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'

import { useDepartmentFindWithApprovedDemand } from '../../../../api/department'
import { simpleReducer } from '../../../../helpers'
import DepartmentPlan from './DepartmentPlan'
import './Departments.less'
import { defaultQueryOptions } from '../../../../constants'
import { renderRestaurantInfo } from '../../../../helpers/department'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { appConfig, langCases } from '../../../../constants/appConfig'
import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { ConfigContext } from '../../../../contexts/configContext'

const { Title, Text } = Typography
const { Search } = Input

function DepartmentsListWithPlans({
  withSearch = false,
  needFilter = false,
  title = '',
  titleStyle = '',
  onAction,
  action,
  tableHeight = 230,
  address = ''
}) {
  const initialState = {
    departments: [],
    search: '',
    hasPlan: false
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { getDepartmentSingularName } = useConfigContextData()
  const { candidate } = useContext(CandidatePanelContext)
  const {
    settings: { data: settingsData }
  } = useContext(ConfigContext)
  /**
   * Get Departments list by address - dadata
   *                      by name - search
   *
   * useDepartmentFindWithApprovedDemand - метод возвращает все рестики и дает гарантию,
   * что если у него есть одобренный план, то он там будет,
   * если одобренного плана нет, то будет просто рестик.
   * @TODO обсудить с бэком название метода.
   */
  const {
    data: departmentApprovedDemandData,
    isSuccess: isSuccessDepartmentApprovedDemand,
    isError: isErrorDepartmentApprovedDemand,
    isLoading: isLoadingDepartmentApprovedDemand
  } = useDepartmentFindWithApprovedDemand(
    {
      address: address,
      search: state.search,
      distance: settingsData[appConfig.settings.maxDistance]
    },
    {
      /**
       * Dadata is expensive request - so we disable all refetch for this query
       */
      ...defaultQueryOptions,
      enabled: !!address || !!state.search
    }
  )

  useEffect(() => {
    if (isSuccessDepartmentApprovedDemand) {
      setState({
        departments: departmentApprovedDemandData?.data
          ?.map(department => ({
            ...department,
            // На списке рестиков при записи на собес нужно показывать только утвержденные данные плана по ресторану
            ...(department.plan?.approved && {
              plan: {
                ...department.plan?.approved
              }
            })
          }))
          .filter(department =>
            needFilter ? department._id !== candidate?.application?.department?._id : true
          ),
        hasPlan: !!departmentApprovedDemandData?.data?.find(department => department.plan?.crewmen)
      })
    } else if (isErrorDepartmentApprovedDemand) {
      message.error('Ошибка поиска орг. единиц')
    }
  }, [
    departmentApprovedDemandData,
    isSuccessDepartmentApprovedDemand,
    isErrorDepartmentApprovedDemand,
    needFilter,
    candidate
  ])

  const columns = [
    {
      title: 'Название и адрес',
      dataIndex: 'name',
      width: 163,
      onCell: () => ({
        colSpan: 2
      }),
      render: (text, item) => (
        <>
          <Row gutter={[12, 6]}>
            <Col span={5} className="department-info border-right">
              {renderRestaurantInfo(text, item, true)}
            </Col>
            <Col span={19}>
              {item?.plan?.crewmen && (
                <Row>
                  <Col span={24}>
                    <DepartmentPlan plan={item?.plan} />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row gutter={12} className="department-comment">
            <Col span={5} className="pt-1 border-right comment-title">
              <Text type="secondary">Комментарий от ЦМ</Text>
            </Col>
            <Col span={19} className="pt-1">
              <Text type="secondary">{item?.comment}</Text>
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'План',
      dataIndex: ['plan'],
      // width: state.hasPlan ? 'auto' : 50,
      onCell: () => ({
        colSpan: 0
      })
      // render: renderPlan
    }
  ]

  const handleSearch = search => setState({ search })
  const tableScroll = useMemo(() => ({ y: tableHeight }), [tableHeight])

  const handleSelectRow = useCallback(
    department => ({
      onClick: () => {
        action?.steps?.findIndex(e => e.name === action?.currentStep) === action?.steps?.length - 1
          ? // если это последний шаг отправляем данные в бэк
            onAction(
              {
                ...action,
                department
              },
              { departmentId: department?._id }
            )
          : // переходим к следующему шагу
            onAction(
              {
                ...action,
                department,
                currentStep:
                  action?.steps[action?.steps?.findIndex(e => e.name === action?.currentStep) + 1]
                    ?.name //appConfig.workflows.steps.interviews.schedulerCustom
              },
              null
            )
      }
    }),
    [action, onAction]
  )

  const handleOnBack = () =>
    action?.steps?.findIndex(e => e.name === action?.currentStep) === 0
      ? // если это первый шаг то сбрасываем действия
        onAction(null, null)
      : // переходим к предыдущему шагу
        onAction(
          {
            ...action,
            currentStep:
              action?.steps?.[action?.steps?.findIndex(e => e.name === action?.currentStep) - 1]
                ?.name
          },
          null
        )

  return (
    <div className="departmentList">
      <Button className="red" onClick={handleOnBack}>
        Назад
      </Button>
      <Title level={4} className={titleStyle}>
        {title || `Выберите ${getDepartmentSingularName(langCases.grand)?.toLowerCase()}:`}
      </Title>
      {withSearch && (
        <div className="mb-3">
          <Search
            className="custom-search"
            placeholder="Поиск"
            enterButton="Поиск"
            allowClear
            disabled={isLoadingDepartmentApprovedDemand}
            onSearch={handleSearch}
          />
          <Text type="secondary">
            Результаты поиска в радиусе{' '}
            {parseFloat((settingsData[appConfig.settings.maxDistance] / 1000).toFixed(2))}км от
            адреса кандидата.
          </Text>
        </div>
      )}
      <Spin spinning={isLoadingDepartmentApprovedDemand} size="large">
        <ConfigProvider locale={ruRu}>
          <Table
            size="small"
            bordered
            scroll={tableScroll}
            columns={columns}
            loading={isLoadingDepartmentApprovedDemand}
            rowKey="_id"
            dataSource={state.departments}
            onRow={handleSelectRow}
            // footer={handleFooter}
            // expandable={{
            //   expandedRowRender: record => (
            //     <Row>
            //       <Col className="department-comment">
            //         <Text type="secondary">Комментарий от ЦМ</Text>
            //       </Col>
            //       <Col>
            //         <Text type="secondary">{record?.comment}</Text>
            //       </Col>
            //     </Row>
            //   ),
            //   expandedRowClassName: () => 'expanded-row',
            //   expandedRowKeys: state?.departments?.map(d => d._id),
            //   indentSize: 0,
            //   showExpandColumn: false
            // }}
          />
        </ConfigProvider>
      </Spin>
    </div>
  )
}

export default DepartmentsListWithPlans
