import { Col, Row, Select, Typography, Alert } from 'antd'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'

import { numberDeclension } from '../../../../../helpers'
import { formatPhoneNumber } from '../../../../../helpers/phone'
import { appConfig } from '../../../../../constants/appConfig'
import './CandidateDuplicateItem.less'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'

const { Text } = Typography
const { Option } = Select

export default function CandidateDuplicateItem({
  candidate,
  wrapperClassName,
  candidates,
  onSelectCandidate
}) {
  const { departmentType, getStateWithLabelByWorkflowAndName } = useConfigContextData()
  const handleSetSelectedCandidate = id => {
    onSelectCandidate(candidates?.find(c => c._id === id))
  }

  const departmentName = useMemo(
    () =>
      candidate?.application?.interviewerDepartment?.name ||
      candidate?.application?.department?.name,
    [candidate?.application]
  )

  const state = useMemo(
    () =>
      getStateWithLabelByWorkflowAndName({
        stateKey: candidate?.state?.name,
        workFlowName: candidate?.vacancy?.workflow?.name
      })?.label || candidate?.state?.name,
    [getStateWithLabelByWorkflowAndName, candidate?.state?.name, candidate?.vacancy]
  )

  return (
    <Col span={24} className={wrapperClassName}>
      <Row gutter={[0, 6]} justify="center">
        <div className="block-title">ВЫБРАТЬ КАНДИДАТА</div>
        <Col span={20}>
          <Alert
            message={`Есть ${candidates?.length} ${numberDeclension(candidates?.length, [
              'кандидат',
              'кандидата',
              'кандидатов'
            ])}, с таким телефоном`}
            type="warning"
            showIcon
          />
        </Col>
        <Col span={20}>
          {candidates?.length > 1 ? (
            <Select
              defaultValue={candidate?._id}
              className="name"
              onChange={handleSetSelectedCandidate}
            >
              {candidates.map(candidate => (
                <Option key={candidate._id} value={candidate._id}>
                  {candidate.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Text>{candidate.name}</Text>
          )}
        </Col>
        <Col span={20} className="candidate-data-block">
          <Col>
            <h3>{candidate.name}</h3>
          </Col>
          <Row className="candidate-data-list">
            <Col span={24}>
              <Row gutter={[0, 16]}>
                <Col span={8}>
                  <Text>Телефон:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>{formatPhoneNumber(candidate.phone)}</Text>
                </Col>
                <Col span={8}>
                  <Text>Email:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>{candidate.email}</Text>
                </Col>
                <Col span={8}>
                  <Text>Дата рождения:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>
                    {candidate.birthday &&
                      dayjs(candidate.birthday).isValid() &&
                      dayjs(candidate.birthday).format(appConfig.formats.shortDate)}
                    {!!candidate.age &&
                      ` (${candidate.age}  ${numberDeclension(candidate.age, [
                        'год',
                        'года',
                        'лет'
                      ])})`}
                  </Text>
                </Col>
                <Col span={8}>
                  <Text>Гражданство:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>{candidate.citizenship}</Text>
                </Col>
                <Col span={8}>
                  <Text>Город:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>{candidate.city}</Text>
                </Col>
                <Col span={8}>
                  <Text>Адрес:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>{candidate.address}</Text>
                </Col>
                <Col span={8}>
                  <Text>Статус:</Text>
                </Col>
                <Col span={12} offset={4}>
                  <Text>{state}</Text>
                </Col>
                {departmentName && (
                  <>
                    <Col span={8}>
                      <Text>{departmentType}:</Text>
                    </Col>
                    <Col span={12} offset={4}>
                      <Text>{departmentName}</Text>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        {candidate.activeStatus && (
          <Col span={20}>
            <Text className="danger-text">
              Статус кандидата не позволяет его повторное использование.
            </Text>
          </Col>
        )}
      </Row>
    </Col>
  )
}
