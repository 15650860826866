import { useCallback, useEffect, useMemo, useState } from 'react'
import { message } from 'antd'

import { useGetVacancies } from '../api/vacancy'
import { appConfig } from '../constants/appConfig'

export const useVacancies = (params, options) => {
  const [vacancies, setVacancies] = useState(undefined)
  const {
    data: dataVacancies,
    isLoading: isLoadingVacancies,
    isError: isErrorVacancies,
    isSuccess: isSuccessVacancies
  } = useGetVacancies(params, {
    retry: 0,
    ...(options || {})
  })

  useEffect(() => {
    isSuccessVacancies && setVacancies(dataVacancies?.data.docs || dataVacancies?.data)
  }, [dataVacancies?.data, isSuccessVacancies])

  const pagination = useMemo(
    () => ({
      current: dataVacancies?.data?.page,
      total: dataVacancies?.data?.totalDocs,
      pageSize: dataVacancies?.data?.limit,
      position: ['topRight', 'bottomRight'],
      showSizeChanger: true
    }),
    [dataVacancies?.data]
  )

  useEffect(() => {
    if (isErrorVacancies) {
      message.error('Ошибка получения вакансий')
    }
  }, [isErrorVacancies])

  const crewmanVacancies = useMemo(
    () => vacancies?.filter(v => v.module === appConfig.modules.crewman) || [],
    [vacancies]
  )

  const ampVacancies = useMemo(
    () => vacancies?.filter(v => v.module === appConfig.modules.amp) || [],
    [vacancies]
  )

  //TODO: попробовать использовать useMemo для избегания пустого массива
  const isActiveVacancy = useCallback(
    id =>
      (vacancies?.length ? vacancies : dataVacancies?.data.docs || dataVacancies?.data || []).find(
        v => v._id === id
      )?.active,
    [vacancies, dataVacancies?.data]
  )

  return {
    vacancies,
    isActiveVacancy,
    isLoadingVacancies,
    isSuccessVacancies,
    isErrorVacancies,
    crewmanVacancies,
    ampVacancies,
    pagination
  }
}
