import { api } from './api'
import { useQuery, useMutation } from 'react-query'
import { defaultQueryOptions } from '../constants'
import { VACANCIES_KEY } from './vacancy'

const WORKFLOWS_KEY = 'workflows'
const WORKFLOWS_CANDIDATE_KEY = 'workflowsCandidate'

const fetchWorkflows = () => {
  return api.get({ path: WORKFLOWS_KEY })
}
export function useQueryWorkflows(options) {
  return useQuery([WORKFLOWS_KEY], fetchWorkflows, { ...defaultQueryOptions, ...options })
}

const workflowsQuery = options => ({
  queryKey: [WORKFLOWS_KEY],
  queryFn: async () => fetchWorkflows(),
  ...defaultQueryOptions,
  ...(options || {})
})
export const workflowsLoader = async queryClient => {
  const query = workflowsQuery()
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

const fetchUpdateWorkflow = data => {
  return api.patch({ path: `${WORKFLOWS_KEY}/${data.name}`, data })
}
export function useMutateUpdateWorkflow(queryClient) {
  return useMutation(fetchUpdateWorkflow, {
    onSuccess: () => {
      queryClient.invalidateQueries(WORKFLOWS_KEY)
      queryClient.refetchQueries(WORKFLOWS_KEY)
      queryClient.refetchQueries(VACANCIES_KEY)
    }
  })
}
const fetchGetDefaultAction = key => {
  return api.get({ path: `${WORKFLOWS_KEY}/candidate/originalActions/${key}` })
}
export function useMutateGetDefaultAction(queryClient) {
  return useMutation(fetchGetDefaultAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(WORKFLOWS_KEY)
      queryClient.refetchQueries(WORKFLOWS_KEY)
    }
  })
}

const fetchWorkflowsCandidate = () => {
  return api.get({ path: `${WORKFLOWS_KEY}/candidate` })
}
export function useQueryWorkflowsCandidate(options) {
  return useQuery([WORKFLOWS_CANDIDATE_KEY], fetchWorkflowsCandidate, options)
}
