import React, { useEffect, useState } from 'react'
import { Button, Col, Input, message, Row, Spin } from 'antd'

import { useMutateAddOrgStructure } from '../../../../../api/orgStructure'
import orgStructureImg from '../../../../../img/orgStructure/main.svg'
import orgStructureLevelImg from '../../../../../img/orgStructure/layers-grey.svg'
import { layers } from '../../../../../constants/orgStructure'
import { useQueryClient } from 'react-query'

function OrgStructureLayers() {
  const queryClient = useQueryClient()
  const [orgStructure, setOrgStructure] = useState(layers)
  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure,
    isLoading: isLoadingOrgStructure
  } = useMutateAddOrgStructure(queryClient)

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохрания данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  const handleChangeLevel = ({ target }) => {
    if (!target.value) {
      for (let i = target.id; i < orgStructure.length; i++) {
        orgStructure[i].value = ''
      }
    }
    orgStructure[target.id].value = target.value
    setOrgStructure([...orgStructure])
  }
  const handleSave = () => {
    const data = {
      layers: orgStructure.map(item => item.value).filter(Boolean),
      units: []
    }
    mutateOrgStructure(data)
  }

  return (
    <div className="orgStructure-layers">
      <Spin spinning={isLoadingOrgStructure} size="large">
        <Row align="middle" justify="center" gutter={32}>
          <Col span={12}>
            <Row align="middle" justify="center">
              <div className="info">
                <div className="info-title">Создайте организационную структуру</div>
                <div className="info-description">
                  Укажите название уровней организационной структуры вашей компании от верхнего к
                  нижнему. Например: Страна &#8594; Дивизион &#8594; Регион &#8594; Подрегион. На
                  следующем шаге вы сможете создать элементы внутри каждого уровня и прикрепить к
                  ним ответственных пользователей.
                </div>
                <img src={orgStructureImg} alt="Create your structure" />
              </div>
            </Row>
          </Col>
          <Col span={12}>
            <Row align="middle" justify="center">
              <div className="layers">
                <div className="layers-title">Добавьте уровни оргструктуры</div>
                {orgStructure.map((level, index) => {
                  const disabled = index && !orgStructure[index - 1]?.value
                  return (
                    <Input
                      key={level.name}
                      id={index}
                      className="layer"
                      placeholder={disabled ? level.name : level.placeholder}
                      onChange={handleChangeLevel}
                      disabled={disabled}
                      value={level.value}
                      prefix={<img src={orgStructureLevelImg} alt="input prefix" />}
                    />
                  )
                })}
                <Button
                  onClick={handleSave}
                  type="primary"
                  block
                  disabled={!orgStructure[0]?.value}
                >
                  Готово
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  )
}

export default OrgStructureLayers
